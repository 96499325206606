import { getApiWrapper } from "./token-wrapper-function.js";

export const getUserChartInfoByDate = async (value, startDate, endDate) => {
    const result = await getApiWrapper(`/api/user_growth?added_by=${value}&start_date=${startDate}&end_date=${endDate}`);
    return result;
};


export const getChartInfoByDate = async (startDate, endDate) => {
    const result = await getApiWrapper(`/api/user_growth?start_date=${startDate}&end_date=${endDate}`);
    return result;
};
