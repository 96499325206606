import { LineChart, ResponsiveContainer, Legend, Tooltip, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import moment from 'moment';
import { Button } from '@paljs/ui/Button';
import { getChartInfoByDate, getUserChartInfoByDate } from '../../actions/chartAction';
import './chart.css';
import async from 'react-select/async';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';

function App() {
  const [rows, setRows] = useState([]);
  const [rowsInstall, setRowsInstall] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [ranges, setRanges] = useState([
    {
      startDate: null,
      endDate: null,
    },
  ]);
  const [value, setValue] = useState('');
  const [isChart, setChart] = useState(false);
  const [newData, setData] = useState([]);
  const [newDataInstall, setDataInstall] = useState([]);
  const [isToggle, setToggle] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [aggregate, setAggregate] = useState(0)
  const [aggregateInstall, setAggregateInstall] = useState(0)
  const previousDate = moment().subtract(1, "months").format("YYYY-MM-DD");
  const currentDate = moment().format("YYYY-MM-DD");
  const [average, setAverage] = useState('');
  const [averageInstall, setAverageInstall] = useState('');
  const [userStartDate, setUserStartDate] = useState('');
  const [userEndDate, setUserEndDate] = useState('');
  const [userAggregate, setuserAggregate] = useState(0)
  const [userAggregateInstall, setuserAggregateInstall] = useState(0)
  const [userAverage, setUserAverage] = useState('');
  const [userAverageInstall, setUserAverageInstall] = useState('');
  useEffect(() => {
    try {
      getchart(previousDate, currentDate)
      // demoChart((response: any) => {
      //   const data = response.data;
      //   console.log('chart info of demo', response);
      //   let s = 0;
      //   const row1 = data.map((v) => {
      //     s = s + v.count;
      //     return { count: v.count, date: v.date, Aggregate: s };
      //   });
      //   setAggregate(row1[row1.length - 1].Aggregate)
      //   console.log('this is row1 data', row1);
      //   setRows(row1);
      // });
    } catch (error) {
      // setError('Something went wrong!');
      // showLoader(false);
    }
  }, []);

  const getchart = async (startDate, endDate) => {
    const response = await getChartInfoByDate(startDate, endDate)
    const data = response.data
    let s = 0;
    const row1 = data.map((v) => {
      s = s + v.count;
      return { count: v.count, date: v.date, Aggregate: s }
    })
    setAggregate(row1[row1?.length - 1]?.Aggregate)
    const dateDifference = moment(endDate).diff(startDate, 'days') + 1;
    let avg = dateDifference ? ((row1[row1?.length - 1]?.Aggregate) / dateDifference) : (row1[row1?.length - 1]?.Aggregate)
    avg = parseInt(avg);
    setAverage(avg)
    console.log("this is row1 data", row1);
    setRows(row1);
    const install_data = response.data_install_count
    s = 0;
    const row2 = install_data.map((item) => {
      s = s + item.install_count;
      return { count: item.install_count, date: item.date, Aggregate: s }
    })
    setAggregateInstall(row2[row2?.length - 1]?.Aggregate)
    avg = dateDifference ? ((row2[row2?.length - 1]?.Aggregate) / dateDifference) : (row2[row2?.length - 1]?.Aggregate)
    avg = parseInt(avg);
    setAverageInstall(avg);
    setRowsInstall(row2);
    setStartDate(startDate);
    setEndDate(endDate);

    console.log(response);
  };

  const getUserChart = async (startDate, endDate) => {
    const response = await getUserChartInfoByDate(value, startDate, endDate)
    const data = response.data;
    let p = 0;
    const row1 = data.map((v) => {
      p = p + v.count;
      return { count: v.count, date: v.date, Aggregate: p };
    });
    setuserAggregate(row1[row1?.length - 1]?.Aggregate)
    const dateDifference = moment(endDate).diff(startDate, 'days') + 1;
    let avg = dateDifference ? ((row1[row1?.length - 1]?.Aggregate) / dateDifference) : (row1[row1?.length - 1]?.Aggregate)
    avg = parseInt(avg);
    setUserAverage(avg)
    console.log('this is row1 data', row1);
    setData(row1);

    const data_install = response.data_install_count;
    p = 0;
    const row2 = data_install.map((item) => {
      p = p + item.install_count;
      return { count: item.install_count, date: item.date, Aggregate: p };
    });
    setuserAggregateInstall(row2[row2?.length - 1]?.Aggregate)
    avg = dateDifference ? ((row2[row2?.length - 1]?.Aggregate) / dateDifference) : (row2[row2?.length - 1]?.Aggregate)
    avg = parseInt(avg);
    setUserAverageInstall(avg);
    setDataInstall(row2);

    setUserStartDate(startDate);
    setUserEndDate(endDate);
    console.log('this is response of newly added chart', response);
    ;
  }

  const dateRangeFilterData = (startDate: any, endDate: any) => {
    //   showLoader(true);
    try {
      !isChart ?
        getchart(startDate, endDate)
        :
        getUserChart(startDate, endDate)

    } catch (error) {
      //     setError('Something went wrong!');
      //     showLoader(false);
    }
  };

  const handleDateRangePicker = (ranges: { range1: any }) => {
    console.log('ranges', ranges);
    const { range1 } = ranges;
    const changedStartDate = moment(range1.startDate).format("YYYY-MM-DD")
    const changedEndDate = moment(range1.endDate).format("YYYY-MM-DD")
    console.log("date difference", moment(changedEndDate).diff(changedStartDate, 'days'))
    changedStartDate !== changedEndDate ? setIsOpen(false) : ''
    setRanges([{ startDate: range1.startDate, endDate: range1.endDate }]);
    dateRangeFilterData(changedStartDate, changedEndDate);
  };

  const onDateRangeToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };
  const newChart = () => {
    try {
      getUserChart(previousDate, currentDate);
      setChart(true);
    } catch (error) {
      //     setError('Something went wrong!');
    }
  };
  const toggleChart = () => {
    setToggle(!isToggle);
  };
  const removeChart = () => {
    setChart(false);
  };
  return (
    <>
      <div>
        <div className="search-container">
          <input
            className="b-TextInput--lg size-100"
            placeholder={'search by user id...'}
            value={value}
            onChange={(event) => handleInputChange(event)}
          />
          <button onClick={newChart} className="b-Button--primary">Search</button>
        </div>
      </div>
      <div>
        <Button
          appearance="outline"
          shape={'Round'}
          size={'Small'}
          Status={'Info'}
          onClick={onDateRangeToggle}
        >
          select date range
        </Button>
        {isOpen && (
          <DateRangePicker
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            direction="horizontal"
            ranges={ranges}
            onChange={handleDateRangePicker}
          />
        )}
      </div>
      <br />
      <Button
        appearance="outline"
        shape={'Round'}
        size={'Small'}
        Status={'Info'}
        onClick={toggleChart}
      >
        {isToggle ? 'Remove Aggregated chart Line' : 'show aggregated?'}
      </Button><br />
      {isChart ? (
        <Button
          appearance="outline"
          shape={'Round'}
          size={'Small'}
          Status={'Info'}
          onClick={removeChart}
        >
          Remove user's chart
        </Button>
      ) : null
      }
      <h1 className="text-heading">
        Report of newly Added Users {!isChart ? (startDate ? `from ${startDate} to ${endDate}` : '') : (userStartDate ? `from ${userStartDate} to ${userEndDate}` : '')}
      </h1>
      <Card>
        <CardHeader style={{ textAlign: 'center', fontWeight: 'bold' }}>NODE COUNT</CardHeader>
        <CardBody>
          <ResponsiveContainer width="100%" aspect={3}>
            <LineChart data={isChart ? newData : rows} margin={{ right: 300 }}>
              <CartesianGrid />
              <XAxis dataKey="date" interval={'preserveStartEnd'} />
              <YAxis />
              <Legend />
              <Tooltip />
              <Line dataKey="count" stroke="red" activeDot={{ r: 8 }} />
              {isToggle ? <Line dataKey="Aggregate" stroke="green" activeDot={{ r: 8 }} /> : null}
            </LineChart>
          </ResponsiveContainer>
        </CardBody>
        <CardFooter>
          <div>Aggregate = {!isChart ? aggregate : userAggregate}</div>
          <div>Average = {!isChart ? average : userAverage}</div>
        </CardFooter>
      </Card>
      <h1 className="text-heading">
        Report of newly Installed Users {!isChart ? (startDate ? `from ${startDate} to ${endDate}` : '') : (userStartDate ? `from ${userStartDate} to ${userEndDate}` : '')}
      </h1>
      <Card>
        <CardHeader style={{ textAlign: 'center', fontWeight: 'bold' }}>INSTALL COUNT</CardHeader>
        <CardBody>
          <ResponsiveContainer width="100%" aspect={3}>
            <LineChart data={isChart ? newDataInstall : rowsInstall} margin={{ right: 300 }}>
              <CartesianGrid />
              <XAxis dataKey="date" interval={'preserveStartEnd'} />
              <YAxis />
              <Legend />
              <Tooltip />
              <Line dataKey="count" stroke="red" activeDot={{ r: 8 }} />
              {isToggle ? <Line dataKey="Aggregate" stroke="green" activeDot={{ r: 8 }} /> : null}
            </LineChart>
          </ResponsiveContainer>
        </CardBody>
        <CardFooter>
          <div>Aggregate = {!isChart ? aggregateInstall : userAggregateInstall}</div>
          <div>Average = {!isChart ? averageInstall : userAverageInstall}</div>
        </CardFooter>
      </Card>
    </>
  );
}
export default App;
